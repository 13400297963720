import clsx from "clsx";
import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";

export type TDirection = "UP" | "DOWN";

export interface ISectionArrowProps {
  direction?: TDirection;
  backgroundTheme?: Theme;
  arrowTheme?: Theme;
}
const SectionArrow: FC<ISectionArrowProps> = ({ direction = "DOWN", backgroundTheme = "default", arrowTheme = "brand" }) => (
  <div data-component={ SectionArrow.name } className={ `w-full flex justify-center ${themeRootClassMap[backgroundTheme]}` }>
    <div
      className={
        clsx(
          "w-0 h-0 border-solid border-t-[0px] border-r-[16px] border-b-[12px] border-l-[16px]",
          "lg:border-r-[40px] lg:border-b-[30px] lg:border-l-[40px]",
          `border-transparent border-b-${arrowTheme}`,
          `${direction === "UP" ? "" : "rotate-[180deg]"}`,
        )
      }
    />
  </div>
);

export default SectionArrow;
