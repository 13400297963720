import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation, isSingleCat } from "@./state";

import {
  IStoryBlokSectionSummaryFuture,
  StoryBlokSummaryFuture,
} from "../StoryBlokSummaryFuture";

export const StoryBlokFormSummaryFuture = ({ flowId, blok }: IStoryBlokComponent<IStoryBlokSectionSummaryFuture>) => {
  const formComponent: IStoryBlokComponent<IStoryBlokSectionSummaryFuture> = {
    blok: {
      ...blok,
      summary: [ {
        ...blok.summary[0],
        header_title: interpolateString(
          blok.summary[0].header_title,
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        header_body_struck_out: interpolateString(
          blok.summary[0].header_body_struck_out || "",
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        header_body: interpolateString(
          blok.summary[0].header_body,
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        list_label: interpolateString(
          blok.summary[0].list_label,
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        footer_title: interpolateString(
          blok.summary[0].footer_title,
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        footer_body_struck_out: interpolateString(
          blok.summary[0].footer_body_struck_out || "",
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        footer_body: interpolateString(
          blok.summary[0].footer_body,
          getFlowFieldValuesForInterpolation(flowId ?? "UNKNOWN", "0", "GET_ALL"),
          Interpolator.FRONTEND,
        ),
        multicat_list_items: isSingleCat(flowId ?? "UNKNOWN") ? [] : blok.summary[0].multicat_list_items,
      } ],
    },
  };
  return (<StoryBlokSummaryFuture {...formComponent} />);
};
